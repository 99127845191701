<template>
	
	<div class="">

		<div class="d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">
			
			<div class="d-flex align-items-center justify-content-between">

				<div class="d-flex align-items-center">
					<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end v-step-project" style="width: 270px;">
						{{ limitString( site.name, 20 ) }}
					</div>
					<router-link :to="{ name: 'site.planning' }" class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2  v-step-sub-nav" :class="$route.name == 'site.planning' ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">view_headline</span>
						Brief
					</router-link>
					<router-link v-if="site.is_built" :to="{ name: 'site.planning.sitemap' }" class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.planning.sitemap' ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">account_tree</span>
						Sitemap
					</router-link>
					<a v-else class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light opacity-50" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Site setup is still in progress, this tab will become available once it's complete" >
						<span class="material-icons-outlined me-2 d-none d-xxl-block">account_tree</span>
						Sitemap
					</a>
					<router-link v-if="site.is_built" :to="{ name: 'site.planning.page', params: { page_id: 4 } }" class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.planning.page' ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">notes</span>
						Content
					</router-link>
					<a v-else class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light opacity-50" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Site setup is still in progress, this tab will become available once it's complete" >
						<span class="material-icons-outlined me-2 d-none d-xxl-block">notes</span>
						Content
					</a>
				</div>
			</div>

			<sub_nav :site="site" />
		</div>

	</div>

</template>

<script>
import sub_nav from '@/components/sub_nav'

export default {
	name: 'components.design_nav',
	props: {
		workspace: Object,
		site: Object,
		modelValue: [String, Number, Object, Boolean],
		is_planning_complete: Boolean,
	},
	components: {
		sub_nav
	},
	computed: {

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},
	},

	methods: {
		limitString(str, maxLength) 
		{
			if (str.length > maxLength) {
				return str.slice(0, maxLength - 3) + '...';
			}
			return str;
		}
	}
}
</script>

<style>

</style>
