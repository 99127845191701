<template>
	
	<div v-if="![ 'site.design' ].includes( $route.name )" class="d-flex align-items-center" style="z-index: 1">
		<a v-if="site && site.is_built" data-bs-toggle="modal" data-bs-target="#board-modal" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light text-info v-step-board">
			<span class="material-icons-outlined me-2 d-none d-xxl-block">view_kanban</span>
			Board
		</a>
		<a v-if="site && site.is_built" data-bs-toggle="modal" data-bs-target="#share" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light text-info v-step-share">
			<span class="material-icons-outlined me-2 d-none d-xxl-block">share</span>
			Share
		</a>
		<a v-if="$tours['build-tour']" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light text-info" @click="start_tour">
			<span class="material-icons-outlined me-2 d-none d-xxl-block">tour</span>
			Take tour
		</a>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'components.sub_nav',

	props: {
		site: Object,
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	components: {
		
	},

	methods: {
		start_tour()
		{
			if ( this.$tours['build-tour'] ) {
				this.$tours['build-tour'].start();
			} 
		}
	}
}
</script>

<style>

</style>
